import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui'
import { Message } from 'element-ui'
import '../style/element-#E50914/index.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import VueI18n from 'vue-i18n'

Vue.prototype.$message = Message
window.$message = Message

Vue.use(ElementUI)
Vue.use(VueI18n)

Vue.config.productionTip = false

const messages = {
  en: {
    msg: {
      input_mail:'Please enter your Netflix account',
      search:'Search',
      refresh:'Refresh',
      no_mail:'The email is empty, please check if the query is correct',
      chose_mail:'Select an email to view details',
      pls_input_mail:'Please enter your email address',
      mail_format:'Please enter a valid email format',
      back:'return',
      back_home:'Return to homepage',
      note:'Note',
      bei_jing_time:'The time of the email is Beijing Time',
      no_mail:"No related emails found",
      no_one_query:"You have not made a query yet",
    }
  },
  zh_CN: {
    msg: {
      input_mail:'请输入您的Netflix账号',
      search:'查找',
      refresh:'刷新',
      no_mail:'邮件为空，请检查查询是否正确',
      chose_mail:'选择邮件以查看详情',
      pls_input_mail:'请输入邮箱地址',
      mail_format:'请输入正确的邮箱格式',
      back:'返回',
      back_home:'返回主页',
      note:'注意',
      bei_jing_time:'邮件时间为北京时间',
      no_mail:"未查询到有关邮件",
      no_one_query:"您还未进行过一次查询",
    }
  },
  zh_TW: {
    msg: {
      input_mail:'請輸入您的Netflix賬號',
      search:'查找',
      refresh:'刷新',
      no_mail:'郵件為空，請檢查查詢是否正確',
      chose_mail:'選擇郵件以查看詳情',
      pls_input_mail:'請輸入郵箱地址',
      mail_format:'請輸入正確的郵箱格式',
      back:'返回',
      back_home:'返回主頁',
      note:'注意',
      bei_jing_time:'郵件時間為北京時間',
      no_mail:"未查詢到有關郵件",
      no_one_query:"您還未進行過一次查詢",
    }
  },
  ko: {
    msg: {
      input_mail:'넷플릭스 계정을 입력하세요',
      search:'검색',
      refresh:'새로 고침',
      no_mail:'이메일이 비어 있습니다, 쿼리가 올바른지 확인하세요',
      chose_mail:'메일을 선택하여 상세 정보를 보세요',
      pls_input_mail:'이메일 주소를 입력하세요',
      mail_format:'올바른 이메일 형식을 입력하세요',
      back:'돌아가기',
      back_home:'홈으로 돌아가기',
      note:'주의',
      bei_jing_time:'이메일 시간은 베이징 시간입니다',
      no_mail:"관련 이메일을 찾을 수 없습니다",
      no_one_query:"아직 한 번의 조회도 하지 않았습니다",
    }
  },
  ja: {
    msg: {
      input_mail:'あなたのNetflixアカウントを入力してください',
      search:'検索',
      refresh:'更新',
      no_mail:'メールが空です、クエリが正しいか確認してください',
      chose_mail:'メールを選択して詳細を見る',
      pls_input_mail:'メールアドレスを入力してください',
      mail_format:'正しいメール形式を入力してください',
      back:'戻る',
      back_home:'ホームに戻る',
      note:'注意',
      bei_jing_time:'メールの時間は北京時間です',
      no_mail:"関連するメールが見つかりませんでした",
      no_one_query:"まだ一度も検索していません",
    }
  }
}

let browserLanguage = navigator.language.split('-')[0];
switch (browserLanguage) {
  case 'zh':
      if (navigator.language.includes('TW') || navigator.language.includes('HK')) {
          browserLanguage = 'zh_TW'
      } else {
          browserLanguage = 'zh_CN'
      }
      break;
  case 'ko':
      browserLanguage = 'ko'
      break;
  case 'ja':
      browserLanguage = 'ja'
      break;
  case 'en':
      browserLanguage = 'en'
      break;
  default:
      browserLanguage = 'zh_CN'
}

// 创建i18n实例
const i18n = new VueI18n({
  locale: browserLanguage, // 设置初始语言
  fallbackLocale: 'zh_CN', // 回退语言
  messages, // 设置语言包
})

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
