import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:mail?',
    name: 'home',
    component: () => import('../views/home.vue'),
    // beforeEnter: (to, from, next) => {
    //   const mail = to.params.mail
    //   console.log(to)
    //   if (mail) {
    //     next()
    //   } else {
    //     next(`/${mail}`)
    //   }
    // }
  },
]

const router = new VueRouter({
  routes
})

export default router
